/*
selected_live_call
live_call   
*/

.livecall_button {

    top: -25px;
    border-radius: 16px;
    width: 80%;
    height:  97px;
    border: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: top;
    color: white;
    position: relative;
    padding-top: 11px;
    box-shadow: 0px -5px 15px rgba(50, 50, 50, 0.15);
}

.livecall_button:first-child {
    top: -25px;
}