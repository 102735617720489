tr.journey_bar_main td:first-child{
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

tr.journey_bar_main td:last-child{
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

tr.journey_bar_main {
    height: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
}

tr.journey_bar_main td {
    height: 100%;
}




