    .care_selected { height:25px }

    .caretooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted #4cc1bd; /* If you want dots under the hoverable text */
        margin:1px;
    }
  
    .caretooltip .caretooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #4cc1bd;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        border: 1px solid white;
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .caretooltip:hover .caretooltiptext {
        visibility: visible;
    }


    .caretooltip_comment {
        justify-content:center;
        height:100%;
    }

    .caretooltip_comment .caretooltiptext_comment {
        visibility: hidden;
        width: 120px;
        background-color: darkgray;
        color: #fff;
        text-align: center;
        padding: 1px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .caretooltip_comment:hover .caretooltiptext_comment {
        visibility: visible;
    }

    .dashboard_careclick_comment_icon {
        background-color: lightgray;
        color: darkgray;
        font-size: 15px;
        font-weight: bold;
        height: 20px;
        width: 20px;
        border-radius:4px;
        margin:1px;
    }

    .caretooltiptext_comment_title{
        font-weight:bold;
        font-size: 14px;
        color:white;
        text-align:left;
        padding-left: 10px;        
    }
    
    .caretooltiptext_comment_text{
        background-color:white;
        color:darkgray;
        margin: 2px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding:2px;
        text-align:left;
        padding-left: 10px;
    }