.reports_chart_dashboard {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reports_chart_dashboard div {
    background-color: white;
}


.disabled {
    opacity: 0.3;
}