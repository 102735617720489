.save_print {
    
    display: flex;
    flex-direction: row;
    padding: 10px;

}


.save_print button {
    margin: 5px;
}


.popupStyle{
    display:flex;
    flex-direction:column;
    text-align: center;
}

.dashboard_divide {
    display: flex;
    flex-direction:row;
    padding:50px;
    flex-wrap: wrap;
}

.dashboard_list {
    padding: 2vh;
    display: flex;
    flex-direction:column;
}

.DashboarItemButton {
    margin-top: 1vh;
}

.sortingspan{
    margin-left:5px;
    font-size: 1.3vh;
}

