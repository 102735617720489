.chartsuite_container {
    display: flex;
    flex-direction: column;
}

.chartsuite_card
{
    height: 300px;
    width: 300px;
    overflow: hidden;
    border-style: solid;
    border-radius: 3px;
    border-width: 2px;

}
