@keyframes fadeIn {
    from {
      opacity: 0;
    }
    
    to {
      opacity: 1;
    }
  }
  
table.rpt
{
  margin-left:auto; 
  margin-right:auto;    
  width: auto;
 
  overflow: auto;
  overflow-y: scroll;

  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
  
  
}

table.rpt thead {
 
  display:table-header-group;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

}

table.rpt tbody {
  display:block;
  overflow:auto;
  /*height:200px;*/
  display:table-header-group;
}

table.rpt th{
    padding: 2px;
    font-size: 0.9em;
    color: white;
    background-color: #4cc1bd;
    border: none;
    height: 35px;
    text-align: center;
    align-items: end;
    vertical-align: center;
    
}

td :contains("Complete") {
  color:green;
}

table.rpt td{
    text-align: center;
    font-size: 0.9em;
    font-weight: 400;
    border: 1px solid #4cc1bd;
    color:#4cc1bd;
    padding: 5px;

    /*width:100%;*/
}

/*  :not(:first-child) */

table.rpt tr {    
/*  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
*/
}

table.rpt tr:nth-child(even) {background: white}
table.rpt tr:nth-child(odd) {background: #dcf5f3}

table.rpt tr:hover{
  /*background-color: #dcf5f3;*/
  background-color: #4cc1bd;
  color: white;
}

table.rpt tr:hover td{
  /*background-color: #dcf5f3;*/
  background-color: #4cc1bd;
  color: white;
}

.DataColumnFilter{
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  border: none;
  color: #4cc1bd;
  padding: 2px;
  
}
.DataColumnFilter:focus {
  border: none;
  outline: none;
}

.greyedOut {
  display: none;
  opacity: 0.2;
  filter: alpha(opacity=10); 
  background-color: #000;
}

.careicons {
  display: flex;
  flex-direction: row;
  margin:1px;
  justify-content:start;
  vertical-align:center;
}



.sortColums {
  display: flex;
  flex-direction:column;
}

.clearRow{
  border-radius: 2px;
}