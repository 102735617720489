.filterMenu {
    position: absolute;
    background-color: #dcf5f3;
    list-style-type: none;
    padding: 10px;
    transition: 0.15s;
    font-size: 8px;
    border-color: #4cc1bd;
    border-style: solid;
    max-height: 250px;
}